
/* connect modal css */

.connect-wallet-modal-wrapper {
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: rgba(0, 0, 0, 0.5);
    overflow: hidden;
    z-index: 2;
}

.connect-wallet-modal {
    width: 30rem;
    background-color: #fff;
    border-radius: .6rem;
}

.btn-cwm > img {
    max-width: 2.4rem;
    height: 2.4rem;
}