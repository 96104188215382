.header {
    /* background-color: #f4f4f4; */
    background-color: #fff;
    border-bottom: 2px solid #d2d2d2;
}

.header-wrapper {
    max-width: 1200px;
}

.logo {
    width: 180px;
}

.nav-item {
    text-decoration: none;
    margin: 0 20px;
    letter-spacing: 1.1px;
}

.nav-item-address {
    width: 120px;
}


@media (max-width: 1200px) {
    .header-wrapper {
        padding: 0 40px;
    }
}

@media (max-width: 992px) {
    .logo-img {
        width: 100px;
    }
}

@media (max-width: 576px) {
    .nav-item-address-sm {
        width: 320px;
    }
}

@media (max-width: 419px) {
    .nav-item {
        font-size: 14px;
    }
    .nav-item-address-sm {
        width: 240px;
    }
}