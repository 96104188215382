.Hero-img {
    width: 40vh;
    border-radius: 4px;
    box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
}

.hero-title {
    font-weight: 800;
}

@media (max-width: 420px) {
    .Hero-img {
        width: 32vh;
    }
}