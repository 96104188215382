
.mint-page-content {
    text-align: center;
    width: 180px;
}


.mint-page-input-group {
    color: #0b1426;
    font-size: 20px;
    font-weight: bold;
}

.mint-qty-input {
    width: 80px;
    height: 36px;
    border-radius: 4px;
    border-color: #d6d6d6;
    text-align: right !important;
}

.total-amount {
    font-family: 'Roboto', sans-serif;
    font-weight: 800 !important;
}

.not-allowed-text {
    width: 420px;
}

@media (max-width: 419px) {
    .mint-result-text {
        width: 320px;
    }
    .mint-qty-input {
        width: 80px;
    }
    .not-allowed-text {
        width: 320px;
    }
}