.content-wrapper {
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #FFFFFF;
}

.home-input-group {
    padding: 12px 200px !important;
    color: #0b1426;
    font-size: 16px;
    font-weight: bold;
}

.web3-btn {
    border: 2px solid #000000;
    background-color: #ffffff;
    outline: none;
}

.web3-btn-alt {
    border: 2px solid #111111;
    background-color: #111111;
    outline: none;
}

.button-85 {
    font-size: 32px;
    padding: 7px 20px;
    background: #000000;
    color: #FFFFFF;
    outline: none;
    font-family: 'Roboto', sans-serif;
}
.button-85 > div > button {
    padding: 5px !important;
    margin-bottom: 14px !important;
    font-weight: 400 !important;
}

.button-86{
    font-size: 32px;
    padding: 7px 20px;
    background: #000000;
    color: #FFFFFF;
    outline: none;
    cursor: not-allowed !important;
    font-family: 'Roboto', sans-serif;
}

.fish-gif {
    bottom: 0px;
    right: 40px;
    width: 20%;
}

@media (max-width: 419px) {
    .button-85 {
        font-size: 24px;
        padding: 6px 12px;
    }
    .button-86{
        font-size: 24px;
        padding: 6px 12px;
    }
    .button-85 > div > button {
        margin-bottom: 4px !important;
    }
}
